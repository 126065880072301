import { api, BACKEND_URL } from '../../global/api/api';

export const apiGetSubjects = () => {
  return api.get(`${BACKEND_URL}/subjects`);
};

export const apiGetSubjectClasses = (subjectId: number) => {
  return api.get(`${BACKEND_URL}/subjects/classes/${subjectId}`);
};

export const apiGetClassesSections = (classId: number) => {
  return api.get(`${BACKEND_URL}/subjects/sections/${classId}`);
};

export const apiGetSectionThemes = (sectionId: number) => {
  return api.get(`${BACKEND_URL}/subjects/themes/${sectionId}`);
};

export const apiStartNewTest = (themeId: number) => {
  return api.get(`${BACKEND_URL}/tests/new/${themeId}`);
};