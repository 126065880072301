import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

function useNotifications(apiResponse: any, apiError: any, onSuccess?: () => void) {
  useEffect(() => {
    if (apiError) {
      const error = apiError as AxiosError<{ message: string }>;
      if (error.response) {
        toast.error(`Ошибка: ${error.response.data.message}`);
      } else {
        toast.error(`Ошибка: ${error.message}`);
      }
    }
  }, [apiError]);

  useEffect(() => {
    if (apiResponse) {
      if (apiResponse.message) {
        toast.success(apiResponse.message);
      }
      if (onSuccess) {
        console.log(`Сработал onSuccess`)
        onSuccess();
      }
    }
  }, [apiResponse]);
}

export default useNotifications;
