import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import useLoadingHook from '../../global/hooks/useLoadingHook';
import { apiGetTest } from '../api/actions';
import { useParams } from 'react-router-dom';
import AnswerButton from './AnswerButton';
import LoadingButton from '../../global/components/LoadingButton';

interface TestFinishedComponentProps {
  testId: number
}

const TestFinishedComponent = ({testId} : TestFinishedComponentProps) => {

  const {id} = useParams();

  const [testInfo, testLoading, testError, executeGetTest] = useLoadingHook(apiGetTest);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <Typography
        variant={'h5'}
      >
        Вы ответили на все вопросы.
      </Typography>
      <Typography>
        Пожалуйста, нажмите на кнопку "Завершить тест" для перехода к результатам.
      </Typography>
    </Box>
  );
};

export default TestFinishedComponent;
