import { useState } from 'react';
import { AxiosResponse } from 'axios';

function useLoadingHook<T>(axiosMethod: (...args: any[]) => Promise<AxiosResponse<T>>): [T | null, boolean, any, (...args: any[]) => Promise<void>] {
  const [dataState, setDataState] = useState<T | null>(null);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<any>(null);

  const execute = async (...args: any[]) => {
    setLoadingState(true);
    setErrorState(null);

    try {
      const response = await axiosMethod(...args);
      setDataState(response.data);
    } catch (error) {
      setErrorState(error);
    } finally {
      setLoadingState(false);
    }
  };

  return [dataState, loadingState, errorState, execute];
}

export default useLoadingHook;