import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import LoadingButton from '../../global/components/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import useLoadingHook from '../../global/hooks/useLoadingHook';
import { apiStartNewTest } from '../api/actions';
import useNotifications from '../../global/hooks/useNotifications';

const ThemePage = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [newTestInfo, newTestLoading, newTestError, getNewTest] = useLoadingHook(apiStartNewTest)

  const goToTest = (testId: number) => {
    navigate('/test/' + testId);
  };

  useNotifications(newTestInfo, newTestError, () => {goToTest(newTestInfo.id)});

  return (
    <Box>
      <LoadingButton
        variant={'contained'}
        onClick={() => {getNewTest(id)}}
        loading={newTestLoading}
      >
        Начать тест
      </LoadingButton>
    </Box>
  );
};

export default ThemePage;
