import React, { useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '../../global/components/LoadingButton';
import { StyledInputLabel, StyledSelect, StyledTextField } from '../../global/styles/theme';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useLoadingHook from '../../global/hooks/useLoadingHook';
import { apiSignUp } from '../api/actions';
import { useNavigate } from 'react-router-dom';
import useNotifications from '../../global/hooks/useNotifications';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Неверный формат электронной почты').required('Необходимо заполнить'),
  password: Yup.string().min(6, 'Too Short!').required('Необходимо заполнить')
});

const RegisterPage = () => {

  const navigate = useNavigate();

  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [registerResponse, registerLoading, registerError, register] = useLoadingHook(apiSignUp);
  const [finishDialogOpen, setFinishDialogOpen] = useState<boolean>(false);

  const openDialog = () => {
    setFinishDialogOpen(true);
  }

  useNotifications(registerResponse, registerError, openDialog);

  return (
    <Box
      sx={{
        bgcolor: '#131F24',
        width: '100%',
        height: '100%'
      }}
    >
      <Stack
        flexDirection={'row-reverse'}
        sx={{
          p: 2
        }}
      >
        <LoadingButton
          variant={'contained'}
          onClick={() => navigate('/sign-in')}
        >
          Войти
        </LoadingButton>
      </Stack>
      <Stack
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={5}
      >
        <Typography
          sx={{
            color: 'white'
          }}
          variant={'h4'}
          fontWeight={600}
        >
          Регистрация учетной записи
        </Typography>

        <Formik
          initialValues={{ email: '', password: '', type: 'child'}}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            register(values);
          }}
        >
          {() => (
            <Form>
              <Stack
                flexDirection={'column'}
                alignItems={'center'}
                gap={2}
                sx={{width: '100%'}}
              >
                <Stack
                  flexDirection={'column'}
                  sx={{width: '100%'}}
                >
                  <Field name="email">
                    {({ field, form: { touched, errors } }: any) => (
                      <StyledTextField
                        {...field}
                        type="email"
                        label="Электронная почта"
                        variant="outlined"
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        sx={{width: '100%'}}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </Field>
                </Stack>
                <Stack flexDirection={'column'} sx={{width: '100%'}}>
                  <Field name="password">
                    {({ field, form: { touched, errors } }: any) => (
                      <StyledTextField
                        {...field}
                        type={togglePassword ? 'text' : 'password'}
                        label="Пароль"
                        variant="outlined"
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
                              onClick={() => {setTogglePassword((prev) => !prev)}}
                            >
                              {!togglePassword && <Visibility sx={{color: 'white'}}/>}
                              {togglePassword && <VisibilityOff sx={{color: 'white'}}/>}
                            </IconButton>,
                        }}
                        sx={{width: '100%'}}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </Field>
                </Stack>
                <Stack flexDirection={'column'} sx={{ width: '100%' }}>
                  <Field name="type">
                    {({ field, form: { touched, errors } }: any) => (
                      <FormControl fullWidth>
                        <StyledInputLabel id="user-type-label">Тип пользователя</StyledInputLabel>
                        <StyledSelect
                          labelId="user-type-label"
                          id="user-type"
                          {...field}
                          label="Тип пользователя"
                        >
                          <MenuItem value="child">Учащийся</MenuItem>
                          <MenuItem value="parent">Родитель</MenuItem>
                          <MenuItem value="teacher">Учитель</MenuItem>
                        </StyledSelect>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <LoadingButton
                  type="submit"
                  loading={registerLoading}
                  variant={'contained'}
                >
                  Зарегистрироваться
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>

      <Dialog
        open={finishDialogOpen}
      >
        <DialogTitle>
          <Typography><strong>Вы успешно прошли регистрацию!</strong></Typography>
        </DialogTitle>
        <DialogContent>
          <Stack flexDirection={'column'}>
            <Typography>
              Поздравляем с успешным прохождением регистрации!
            </Typography>
            <Typography>
              Пожалуйста, нажмите на кнопку "Войти" для перехода на страницу входа в систему.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            onClick={() => {navigate('/sign-in')}}
          >
            Войти
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RegisterPage;
