import React, { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import useLoadingHook from '../../global/hooks/useLoadingHook';
import useNotifications from '../../global/hooks/useNotifications';
import { apiGetSubjectClasses } from '../api/actions';
import { SubjectType } from '../types/subject.type';
import SubjectCard from '../components/SubjectCard';

const SubjectPage = () => {

  const [subjectsList, subjectsLoading, subjectsError, fetchSubjects] = useLoadingHook(apiGetSubjectClasses);

  useEffect(() => {
    fetchSubjects(1);
  }, [])

  useNotifications(subjectsList, subjectsError);

  return (
    <Box>
      <Stack
        flexDirection={'row'}
      >
        {subjectsList?.map((subject : SubjectType) => {
          return (
            <SubjectCard id={subject.id}
                         title_ru={subject.title_ru} title_kz={subject.title_kz} title_en={subject.title_en}
                         description_ru={subject.description_ru} description_kz={subject.description_kz} description_en={subject.description_en}
                         avatar_url={subject.avatar_url} mini_url={subject.mini_url}
                         type={'subject'}
            />
          )
        })}
      </Stack>
    </Box>
  );
};

export default SubjectPage;
