import React from 'react';
import { Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { SubjectCardProps } from '../types/subject.type';
import { useNavigate } from 'react-router-dom';

const SubjectCard = ({
                       id,
                       title_ru,
                       title_kz,
                       title_en,
                       description_ru,
                       description_kz,
                       description_en,
                       avatar_url,
                       mini_url,
                       type
} : SubjectCardProps) => {

  const navigate = useNavigate();

  const goTo = () => {
    switch (type) {
      case 'subject':
        navigate(`/class/${id}`);
        break;
      case 'class':
        navigate(`/section/${id}`);
        break;
      case 'section':
        navigate(`/theme/${id}`);
        break;
    }
  }

  return (
    <Card
      sx={{
        height: '150px',
        width: '200px',
        maxWidth: 345,
        p: 2,
        display: 'flex',
        flexDirection: 'column', // Flex direction column to stack children vertically
        justifyContent: 'space-between' // Distribute space evenly between children
      }}
    >
      <CardContent
        sx={{
          p: 0,
          flex: '1 1 auto' // Allow CardContent to grow and take up remaining space
        }}
      >
        <Typography>
            {title_ru}
        </Typography>
      </CardContent>
      <CardActions
        sx={{p: 0}}
      >
        <Button
          variant={'contained'}
          size={'small'}
          onClick={() => {goTo()}}
        >
          Перейти
        </Button>
      </CardActions>
    </Card>
  );
};

export default SubjectCard;
