import { api, BACKEND_URL } from '../../global/api/api';

interface SignUpDto {
  email: string;
  password: string;
  type: string;
}

interface SignInDto {
  email: string;
  password: string;
}

export const apiSignUp = (dto: SignUpDto) => {
  return api.post(`${BACKEND_URL}/auth/sign-up`, dto);
}

export const apiSignIn = (dto: SignInDto) => {
  return api.post(`${BACKEND_URL}/auth/sign-in`, dto);
}