import { api, BACKEND_URL } from '../../global/api/api';
import { AnswerSubmitDto } from '../types/test.types';

export const apiGetTest = (testId: number) => {
  return api.get(`${BACKEND_URL}/tests/${testId}`);
};

export const apiSubmitAnswer = (answerDto: AnswerSubmitDto) => {
  return api.post(`${BACKEND_URL}/tests/submit-answer`, answerDto)
};

export const apiFinishTest = (testId: number) => {
  return api.get(`${BACKEND_URL}/tests/finish/${testId}`);
};

export const apiGetTestResult = (testId: number) => {
  return api.get(`${BACKEND_URL}/tests/result/${testId}`);
}