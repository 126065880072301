import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import { TestResultType } from '../types/test.types';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import { getColorByPercentage } from '../../global/helpers/getColorByPercentage';
import AnswerButton from './AnswerButton';

interface ResultQuestionsProps {
  resultInfo: TestResultType
}

const ResultQuestions = ({resultInfo} : ResultQuestionsProps) => {

  return (
    <Box
      sx={{
        my: 2,
        background: 'transparent'
      }}
    >
      {resultInfo.test.questions.map((question) => {
        return (
          <Accordion
            sx={{
              background: '#131F24'
            }}
          >
            <AccordionSummary>
              <Stack
                flexDirection={'column'}
              >
                <Typography>
                  {question.task.text_ru}
                </Typography>
                <Typography>
                  {question.task.question_ru}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                flexDirection={'column'}
                gap={1}
              >
                {!question.user_answer && (
                  <Typography>
                    <i>Вы не ответили на данный вопрос</i>
                  </Typography>
                )}
                {question.task.answers.map((answer) => {
                  return (
                    <AnswerButton
                      answer={answer}
                      handleClick={() => {}}
                      isAnswered={true}
                      isSelected={question.user_answer?.answer_id === answer.id}
                      disabled={true}
                    />
                  )
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  );
};

export default ResultQuestions
