import { createTheme, InputLabel, Select, styled, TextField } from '@mui/material';

export const globalTheme = createTheme({});

export const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    padding: '0',
    backgroundColor: '#202F36',
  },
  '& .MuiInputBase-input': {
    '& .MuiFilledInput': {
      backgroundColor: '#202F36',
    },
    backgroundColor: '#202F36',
    borderRadius: '5px',
    borderColor: 'white',
    color: 'white',
  },
  '& .MuiFilledInput': {
    backgroundColor: '#202F36',
    color: 'white'
  },
  '& .MuiInputLabel-root': {
    color: 'white'
  },
});

export const StyledSelect = styled(Select)({
  color: 'white',
  backgroundColor: '#202F36',
  '& .MuiSvgIcon-root': {
    color: 'white'
  },
})

export const StyledInputLabel = styled(InputLabel)({
  color: 'white'
})