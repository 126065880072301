import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import TestComponent from '../components/TestComponent';
import useLoadingHook from '../../global/hooks/useLoadingHook';
import { apiFinishTest, apiGetTest, apiSubmitAnswer } from '../api/actions';
import useNotifications from '../../global/hooks/useNotifications';
import { TestType } from '../types/test.types';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import TestFinishedComponent from '../components/TestFinishedComponent';
import LoadingButton from '../../global/components/LoadingButton';
import { toast } from 'react-toastify';

const TestPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [testInfo, testLoading, testError, executeGetTest] = useLoadingHook<TestType>(apiGetTest);
  const [answerInfo, answerLoading, answerError, executeSubmit] = useLoadingHook(apiSubmitAnswer);
  const [finishResult, finishLoading, finishError, executeFinish] = useLoadingHook(apiFinishTest);
  const [currentTask, setCurrentTask] = useState<number>(0);
  const [finishDialog, setFinishDialog] = useState<boolean>(false);

  useEffect(() => {
    executeGetTest(id);
  }, []);

  const onGetTest = () => {
    if (testInfo) {
      if (testInfo.completed_at) {
        toast.info('Вы уже завершили данный тест. Перенаправляем на страницу результатов.');
        goToResultPage();
      } else {
        getCurrentQuestion();
      }
    }
  }

  const getCurrentQuestion = () => {
    if (testInfo) {
      const questions = testInfo.questions;
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        if (!question.user_answer) {
          setCurrentTask(i);
          break;
        }
        if ((i+1) === questions.length && question.user_answer) {
          setCurrentTask(i);
          break;
        }
      }
    }
  }

  const handleAnswer = (answer: any) => {
    executeSubmit(answer);
  };

  const goToNextTask = () => {
    if (testInfo) {
      if ((currentTask) < testInfo.questions.length) {
        setCurrentTask((prev) => prev + 1);
      }
    }
  };

  const handleFinish = () => {
    if (testInfo) {
      if ((currentTask + 1) < testInfo.questions.length) {
        setFinishDialog(true)
      } else {
        executeFinish(testInfo.id)
      }
    }
  };

  const goToResultPage = () => {
    navigate('/test/result/' + id);
  };

  useNotifications(testInfo, testError, onGetTest);
  useNotifications(finishResult, finishError, goToResultPage)
  useNotifications(answerInfo, answerError);

  const memoizedQuestion = useMemo(() => {
    return testInfo ? testInfo.questions[currentTask] : null;
  }, [testInfo, currentTask]);

  if (testLoading) {
    return <CircularProgress />
  }

  if (testInfo) {
    return (
      <Box>
        {(currentTask + 1) < testInfo.questions.length && memoizedQuestion &&  (
          <TestComponent
            question={memoizedQuestion}
            onAnswer={handleAnswer}
            answerLoading={answerLoading}
            goToNextTask={goToNextTask}
          />
        )}
        {(currentTask + 1) === testInfo.questions.length && <TestFinishedComponent
          testId={testInfo.id}
        />}
        <Stack
          flexDirection={'row'}
        >
          <LoadingButton
            variant={'contained'}
            onClick={() => {handleFinish()}}
            loading={finishLoading}
          >
            Завершить тест
          </LoadingButton>
        </Stack>
        <Dialog
          open={finishDialog}
          onClose={() => {setFinishDialog(false)}}
        >
          <DialogTitle>
            Пожалуйста, подтвердите завершение теста
          </DialogTitle>
          <DialogContent>
            <Stack flexDirection={'column'}>
              <Typography>
                <strong>Внимание!</strong>
              </Typography>
              <Typography>
                Вы пытаетесь закончить тест, до того как ответили на все вопросы. В случае завершения теста - неотвеченные вопросы будут зачтены в статистике как "Неправильно отвеченные".
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant={'contained'}
              color={'error'}
              onClick={() => {setFinishDialog(false)}}
            >
              Отменить завершение
            </Button>
            <LoadingButton
              variant={'contained'}
              onClick={() => {executeFinish(testInfo.id)}}
              loading={finishLoading}
            >
              Завершить тест
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return <CircularProgress />
};

export default TestPage;
