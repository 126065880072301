import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { AnswerType, TestComponentType } from '../types/test.types';
import LoadingButton from '../../global/components/LoadingButton';
import { shuffleArray } from '../../global/helpers/shuffleArray';
import AnswerButton from './AnswerButton';

const TestComponent = ({question, onAnswer, answerLoading, goToNextTask} : TestComponentType) => {

  const { id, test_id, task, user_answer } = question;
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [shuffledAnswers, setShuffledAnswers] = useState<AnswerType[]>([]);
  const [isAnswered, setIsAnswered] = useState<boolean>(false);

  useEffect(() => {
    console.log(`question изменился`)
    setIsAnswered(false);
  }, [question])

  useEffect(() => {
    if (user_answer) {
      setSelectedAnswer(user_answer.answer_id);
    }
  }, [user_answer])

  useEffect(() => {
    setShuffledAnswers(shuffleArray(task.answers));
  }, [task.answers]);

  const selectAnswer = (answerId: number) => {
    setSelectedAnswer(answerId);
  }

  const submitAnswer = () => {
    if (selectedAnswer && onAnswer) {
      onAnswer({
        test_question_id: id,
        test_id,
        answer_id: selectedAnswer
      });

      setIsAnswered(true);
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <Typography
        variant={'h5'}
      >
        {task.title_ru}
      </Typography>
      <Typography>
        {task.text_ru}
      </Typography>
      <Typography>
        {task.question_ru}
      </Typography>
      {
        shuffledAnswers.map((answer) => {
          return (
            <AnswerButton
              answer={{...answer}}
              handleClick={selectAnswer}
              isSelected={answer.id === selectedAnswer}
              disabled={!!user_answer || isAnswered}
              isAnswered={isAnswered}
            />
          )
        })
      }
      <Stack
        flexDirection={'row'}
        justifyContent={'flex-end'}
      >
        <LoadingButton
          variant={'contained'}
          disabled={!selectedAnswer || isAnswered}
          onClick={() => {submitAnswer()}}
          loading={answerLoading}
        >
          Ответить
        </LoadingButton>

        {goToNextTask && (
          <LoadingButton
            variant={'contained'}
            disabled={!isAnswered}
            onClick={() => {goToNextTask();}}
            loading={answerLoading}
          >
            Перейти к следующему вопросу
          </LoadingButton>
        )}
      </Stack>
    </Box>
  );
};

export default TestComponent;
