export function shuffleArray<T>(array: T[]): T[] {

  console.log(`Сработал Shuffle`)

  let shuffledArray = array.slice(); // Копируем исходный массив
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Меняем местами элементы
  }
  return shuffledArray;
}