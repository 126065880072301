import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserRouter } from 'react-router-dom';
import RegisterPage from './Register/pages/RegisterPage';
import AuthPage from './Register/pages/AuthPage';
import SubjectPage from './Subject/pages/SubjectPage';
import ClassPage from './Subject/pages/ClassPage';
import SectionPage from './Subject/pages/SectionPage';
import TestPage from './Test/pages/TestPage';
import ThemePage from './Subject/pages/ThemePage';
import TestResultPage from './Test/pages/TestResultPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <SubjectPage />
  },
  {
    path: "/class/:id",
    element: <ClassPage />
  },
  {
    path: "/section/:id",
    element: <SectionPage />
  },
  {
    path: "/theme/:id",
    element: <ThemePage />
  },
  {
    path: "/test/:id",
    element: <TestPage />
  },
  {
    path: "/test/result/:id",
    element: <TestResultPage />
  },
  {
    path: "/sign-up",
    element: <RegisterPage />
  },
  {
    path: "sign-in",
    element: <AuthPage />
  },
]);

const App: React.FC = () => {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
