import React from 'react';
import { Box, Typography } from '@mui/material';
import { TestResultType } from '../types/test.types';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import { getColorByPercentage } from '../../global/helpers/getColorByPercentage';

interface ResultChartProps {
  resultInfo: TestResultType
}

const ResultChart = ({resultInfo} : ResultChartProps) => {

  return (
    <Box>
      <Gauge
        width={200}
        height={200}
        value={resultInfo.percentage}

        sx={{
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 26,
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: getColorByPercentage(resultInfo.percentage),
          },
        }}
        text={() => `${resultInfo?.percentage}%`}
      />
      <Typography>
        Количество вопросов - {resultInfo.total_questions}
      </Typography>
      <Typography>
        Количество правильных ответов - {resultInfo.correct_answers}
      </Typography>
      <Typography>
        Количество неправильных ответов - {resultInfo.incorrect_answers}
      </Typography>
    </Box>
  );
};

export default ResultChart
