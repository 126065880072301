export const getColorByPercentage = (percentage: number) => {
  if (percentage > 90) {
    return '#4ce600'
  } else if (percentage > 60 && percentage <= 89) {
    return '#ffffbf'
  } else if (percentage > 40 && percentage <= 59) {
    return '#fdae61'
  } else if (percentage <= 39) {
    return '#d7191c'
  }

  return '#d7191c'
}