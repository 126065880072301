import { Check, Clear } from '@mui/icons-material';
import React from 'react';
import LoadingButton from '../../global/components/LoadingButton';
import { AnswerType } from '../types/test.types';

export interface AnswerButtonProps {
  answer: AnswerType;
  handleClick: (answerId: number) => void;
  isSelected?: boolean;
  disabled?: boolean;
  isAnswered?: boolean;
}

const AnswerButton = ({answer, handleClick, isSelected, disabled, isAnswered} : AnswerButtonProps) => {

  const getEndIcon = () => {
    if (isAnswered && isSelected && answer.is_correct) {
      return <Check color={'success'}/>
    } else if (isAnswered && isSelected && !answer.is_correct) {
      return <Clear color={'error'}/>
    } else if (isAnswered && answer.is_correct) {
      return <Check />
    } else {
      return ''
    }
  }

  return (
    <LoadingButton
      sx={{
        width: '400px',
        height: '60px',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: '1px solid rgba(25, 118, 210, 1)',
          color: 'rgba(25, 118, 210, 1)'
        }
      }}
      variant={isSelected ? 'contained' : 'outlined'}
      onClick={() => handleClick(answer.id)}
      disabled={disabled}
      endIcon={getEndIcon()}
    >
      {answer.text_ru}
    </LoadingButton>
  );
};

export default AnswerButton;
