import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import useLoadingHook from '../../global/hooks/useLoadingHook';
import { apiGetTestResult } from '../api/actions';
import useNotifications from '../../global/hooks/useNotifications';
import { TestResultType, TestType } from '../types/test.types';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import { getColorByPercentage } from '../../global/helpers/getColorByPercentage';
import LoadingButton from '../../global/components/LoadingButton';
import { apiStartNewTest } from '../../Subject/api/actions';
import ResultChart from '../components/ResultChart';
import ResultQuestions from '../components/ResultQuestions';

const TestResultPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [resultInfo, resultLoading, resultError, executeGetResult] = useLoadingHook<TestResultType>(apiGetTestResult);
  const [newTestInfo, newTestLoading, newTestError, getNewTest] = useLoadingHook(apiStartNewTest);
  const [selectedTab, setSelectedTab] = useState<string>('chart');

  const goToTest = (testId: number) => {
    navigate('/test/' + testId);
  };

  useEffect(() => {
    executeGetResult(id);
  }, [])

  useNotifications(newTestInfo, newTestError, () => {goToTest(newTestInfo.id)});
  useNotifications(resultInfo, resultError);

  const handleSelectTab = (event: React.SyntheticEvent, value: string) => {
    setSelectedTab(value);
  }

  if (resultInfo) {
    return (
      <Box>
        <Typography
          variant={'h5'}
        >
          Результаты теста #{resultInfo.test.id}
        </Typography>
        <Typography
          variant={'h6'}
        >
          Тема: {resultInfo.test.theme.title_ru}
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={handleSelectTab}
        >
          <Tab
            value={'chart'}
            label={'Общие результаты'}
          />
          <Tab
            value={'questions'}
            label={'Вопросы и ответы'}
          />
        </Tabs>
        {selectedTab === 'chart' && (
          <ResultChart
            resultInfo={resultInfo}
          />
        )}
        {selectedTab === 'questions' && (
          <ResultQuestions
            resultInfo={resultInfo}
          />
        )}
        <LoadingButton
          variant={'contained'}
          onClick={() => {getNewTest(resultInfo.test.theme.id)}}
          loading={newTestLoading}
        >
          Запустить новый тест
        </LoadingButton>
      </Box>
    );
  }

  return <CircularProgress />
};

export default TestResultPage;
